import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import BlockContent from "@sanity/block-content-to-react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ProjectTile from "../components/ProjectTile";
import withGraphQLErrors from "../hocs/withGraphQLErrors";
import ContentLayout from "../primitives/ContentLayout";
import SectionLayout from "../primitives/SectionLayout";
import PortableText, { InternalLinkRenderer } from "../primitives/PortableText";
import LinkButton from "../primitives/LinkButton";
import styles from "../styles/home.module.scss";

export const query = graphql`
  query IndexPageQuery {
    grid: sanityHomeGrid(_id: { eq: "homeGrid" }) {
      projects {
        ... on SanityHomeBlock {
          _rawContent(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityProject {
          slug {
            current
          }
          subtitle
          title
          thumbImage {
            asset {
              _id
            }
            crop {
              bottom
              left
              right
              top
            }
            hotspot {
              height
              x
              width
              y
            }
          }
        }
      }
    }
    page: sanityRoute(slug: { current: { eq: "/" } }) {
      page {
        _rawContent(resolveReferences: { maxDepth: 10 })
        meta {
          metaDescription
        }
        openGraphImage {
          asset {
            _id
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const { grid = {}, page = {} } = data || {};
  const { page: pageContent = {} } = page;

  return (
    <Layout isHome>
      <Seo
        description={get(page, "page.meta.metaDescription", "")}
        image={get(page, "page.openGraphImage", null)}
      />

      <ContentLayout size="medium">
        <div className={styles.grid}>
          {grid.projects &&
            grid.projects.map(
              ({ slug, title, subtitle, thumbImage, _rawContent }, i) => {
                if (slug) {
                  return (
                    <div
                      className={styles[`gridItem${i + 1}`]}
                      key={slug.current}
                    >
                      <ProjectTile
                        slug={`/projets/${slug.current}/`}
                        title={title}
                        subtitle={subtitle}
                        thumbnail={thumbImage}
                      />
                    </div>
                  );
                }

                return (
                  <div
                    className={`${styles[`gridItem${i + 1}`]} ${
                      styles.gridItemText
                    }`}
                    key={i + 1}
                  >
                    <BlockContent
                      blocks={_rawContent}
                      serializers={{
                        marks: { internalLink: InternalLinkRenderer },
                      }}
                    />
                  </div>
                );
              }
            )}
        </div>
        <div style={{ textAlign: "center" }}>
          <LinkButton to="/projets/">Voir plus de réalisations</LinkButton>
        </div>
      </ContentLayout>

      {pageContent._rawContent &&
        pageContent._rawContent.map(
          ({ _key, sectionContent, backgroundColor }) => (
            <SectionLayout backgroundColor={backgroundColor} key={_key}>
              <ContentLayout>
                <PortableText blocks={sectionContent} />
              </ContentLayout>
            </SectionLayout>
          )
        )}
    </Layout>
  );
};

export default withGraphQLErrors()(IndexPage);
